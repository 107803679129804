function updateFollowImagePosition(e, followImagesActive) {
  followImagesActive.forEach((isActive, followImage) => {
    if (isActive) {
      const offsetX = e.clientX - followImage.width / 2;
      const offsetY = e.clientY - followImage.height / 2;

      followImage.style.left = `${offsetX}px`;
      followImage.style.top = `${offsetY}px`;
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const clientCells = document.querySelector('.client-cell');
  if (clientCells) {
    // Reset follow image opacity to 0 when the page loads
    const followImages = document.querySelectorAll('.follow-image');
    followImages.forEach((followImage) => {
      followImage.style.opacity = '0';
    });

    const elements = document.querySelectorAll('.is-style-warm-black');
    const bodyElement = document.querySelector('body');

    if (elements.length >= 1) {
      elements[0].classList.remove('is-style-warm-black');
      bodyElement.style.backgroundColor = '#1a1919';
    }

    const sections = document.querySelectorAll('.columns--three.columns--three--relationship');

    sections.forEach((section) => {
      const clientCells = section.querySelectorAll('.client-cell');
      const followImagesActive = new Map();

      clientCells.forEach((cell) => {
        const clientLogo = cell.querySelector('.client-logo');
        const followImage = cell.querySelector('.follow-image');

        if (clientLogo && followImage) {
          followImagesActive.set(followImage, false);

          let touchStartTimestamp = 0;
          // Delay in ms to determine if a touch is a tap or a swipe
          const touchDelay = 200;

          function enableFollowImage() {
            followImagesActive.set(followImage, true);
            followImage.style.opacity = '1';
          }

          function disableFollowImage() {
            followImagesActive.set(followImage, false);
            followImage.style.opacity = '0';
          }

          // Touch events
          clientLogo.addEventListener('touchstart', (event) => {
            touchStartTimestamp = event.timeStamp;
            enableFollowImage();
          });

          clientLogo.addEventListener('touchend', (event) => {
            const touchEndTimestamp = event.timeStamp;
            if (touchEndTimestamp - touchStartTimestamp < touchDelay) {
              // If the touch duration is less than the delay, navigate to the link
              const link = clientLogo.closest('a');
              if (link) {
                link.click();
              }
            }
            disableFollowImage();
          });
        }
      });

      clientCells.forEach((cell) => {
        const followImage = cell.querySelector('.follow-image');
        // Shows the followImage when the mouse enters the clientCell
        cell.addEventListener('mouseenter', () => {
          followImage.style.opacity = '1';
          followImagesActive.set(followImage, true);
        });
        // Set opacity to 0 when the mouse leaves the clientCell
        cell.addEventListener('mouseleave', () => {
          followImage.style.opacity = '0';
          followImagesActive.set(followImage, false);
        });
      });

      // Attach the updateFollowImagePosition function to the mousemove event
      function isDesktopViewport() {
        return window.matchMedia('(min-width: 640px)').matches;
      }

      if (isDesktopViewport()) {
        document.addEventListener('mousemove', (e) => {
          updateFollowImagePosition(e, followImagesActive);
        });

        section.addEventListener('mouseenter', () => {
          followImagesActive.forEach((_, followImage) => {
            followImage.style.opacity = '0';
          });
        });

        section.addEventListener('mouseleave', () => {
          followImagesActive.forEach((isActive, followImage) => {
            if (!isActive) {
              followImage.style.opacity = '0';
            }
          });
        });
      }
    });

    // Disable mousemove event on links to prevent the image from moving
    const links = document.querySelectorAll('a');

    links.forEach((link) => {
      link.addEventListener('click', () => {
        document.removeEventListener('mousemove', (e) => {
          updateFollowImagePosition(e, followImagesActive);
        });
      });
    });

    // Pageshow event to reset followImage opacity when returning to the page
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        // Page navigation (browser back button)
        followImages.forEach((followImage) => {
          followImage.style.opacity = '0';
        });
      }
    });
  }
});
