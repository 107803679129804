import { breakpoints } from './constants'

export default class VideoController {
  constructor(element, wrapperSelector = '.a-video-controller__wrapper', controllerSelector = '.a-video-controller__input') {
    this.wrapper = element.querySelector(wrapperSelector);
    this.controller = element.querySelector(controllerSelector);

    if (this.wrapper && this.controller) {
      this.video = this.wrapper.getElementsByTagName("video")[0];

      this.#controlState();
      this.#playVideoOnBreakpoints();
    }
  }

  // control video state on wrapper click
  #controlState() {
    this.wrapper.addEventListener("click", () => {
      if (this.video.paused) {
        this.controller.checked = false;
        this.video.play();
      } else {
        this.controller.checked = true;
        this.video.pause();
      }
    });
  }

  #playVideoOnBreakpoints() {
    const breakpoint = "(max-width: " + breakpoints.DESKTOP_BREAKPOINT + ")";
    this.mobileView = window.matchMedia(breakpoint);

    if (this.mobileView.matches)
      this.video.setAttribute("poster", this.video.getAttribute("data-poster"));
    else
      this.video.play();
  }
}
