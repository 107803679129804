export default class PlayOnHover {
  constructor(dataAttrName = '[data-play-on-hover]') {
    const videosNodeList = document.querySelectorAll(dataAttrName);

    if (!videosNodeList) {
      console.info('[PlayOnHover] No videos detected on page');
      return;
    }

    if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      console.info('`prefers-reduced-motion` detected. Video autoplay on hover disabled.');
      return;
    }

    try {
      videosNodeList.forEach((videoEl) => {
        const innerVideo = videoEl.nodeName != 'VIDEO' ? videoEl.querySelector('video') : videoEl;

        if (innerVideo.nodeName == 'VIDEO') {
          videoEl.addEventListener('mouseover', (e) => {
            innerVideo.play();
          });
          videoEl.addEventListener('mouseout', (e) => {
            innerVideo.pause();
          });
        } else {
          console.error('[PlayOnHover] Video wrapper detected, but no <video> element found');
        }
      });
    } catch {
      console.warn(
        '[PlayOnHover] Something somewhere should have been done differently',
      );
    }
  }
}
