const RedirectModalInit = () => {
  // General config options
  const maxVisitCount = 10; // Number of visits before hiding the modal permanently
  const localStorageKey = 'vt-visits';

  // Shared Elements
  const redirectModalContainer = document.querySelector('.o-redirect-modal__container');
  if (!redirectModalContainer) {
    return;
  }

  const redirectModalCta = redirectModalContainer.querySelector('.o-redirect-modal__cta-close');
  const redirectModalBtnClose = redirectModalContainer.querySelector('.o-redirect-modal__btn-close');

  const checkRender = () => {
    const modalElementExists = !!redirectModalContainer;
    const hasVisitedNewSite = checkLocalStorage();

    if (modalElementExists && !hasVisitedNewSite) {
      showModal();
    }

    document.addEventListener('keydown', handleEscKey);
    redirectModalCta.addEventListener('click', hideModal);
    redirectModalBtnClose.addEventListener('click', hideModal);
  };

  const showModal = () => {
    redirectModalContainer.parentElement.style.display = 'flex';
    redirectModalContainer.setAttribute('open', '');
    document.body.classList.add('no-scroll');
    localStorage.setItem('redirect-modal-seen', 'true');
  };

  const hideModal = () => {
    redirectModalContainer.parentElement.style.display = 'none';
    redirectModalContainer.removeAttribute('open');
    document.body.classList.remove('no-scroll');
  };

  const handleEscKey = (e) => {
    if (e.key === 'Escape') {
      hideModal();
    }
  };

  const checkLocalStorage = () => {
    const visitsCount = parseInt(localStorage.getItem(localStorageKey), 10);
    console.log('Total number of visits that showed the redirect modal: ', visitsCount);
    if (!visitsCount) {
      window.localStorage.setItem(localStorageKey, 1);
      return false;
    }

    if ((visitsCount && isModalVisible()) || visitsCount < maxVisitCount) {
      window.localStorage.setItem(localStorageKey, visitsCount + 1);
      return false;
    }

    return true;
  };

  const isModalVisible = () => redirectModalContainer.parentElement.style.getPropertyValue('display') === 'flex';

  // Execute the core function after everything is set.
  checkRender();
};

export default RedirectModalInit;
