const appendUtms = (url) => {
  const urlObj = new URL(url, window.location.origin);
  const params = new URLSearchParams(window.location.search);

  const utms = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];

  utms.forEach((utm) => {
    if (params.has(utm)) {
      urlObj.searchParams.set(utm, params.get(utm)); // Replace or append the UTM parameter
    }
  });

  return urlObj.toString();
};

const linkModifications = () => {
  const links = document.querySelectorAll('[data-append-utm="true"]');

  links.forEach((link) => {
    link.href = link.href ? appendUtms(link.href) : null;
  });
};

const LinkAppendsInit = () => {
  linkModifications();
};

export default LinkAppendsInit;
